import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Patient from '@/v2/containers/patient'
import Search from '@/v2/containers/search'
import Calendar from '@/v2/containers/calendar'
import Login from '@/v2/containers/auth-login'
import NewPassword from '@/v2/containers/auth-new-password'
import ResetPassword from '@/v2/containers/auth-reset-password'
import InsuranceForm from '@/v2/containers/insurance-form'

const MobileMainRoutes = () => {
  const rootPath = `/v2/search`
  return (
    <Switch>
      <Route path="/v2/calendar/:view?/:date?" component={Calendar} />
      <Route path="/v2/search" component={Search} />
      <Route
        exact
        path="/v2/patient/:id?"
        render={({ match }) => (
          <Redirect to={`/v2/patient/${match.params.id}/profile`} />
        )}
      />
      <Route path="/v2/patient/:id?/:view?" component={Patient} />
      <Route exact path="/v2" render={() => <Redirect to={rootPath} />} />
      <Route
        exact
        path="/settings"
        render={() => {
          window.location.href = '/settings'
          return null
        }}
      />
      {/* Authentication Routes */}
      <Route exact path="/v2/login" component={Login} />
      <Route exact path="/v2/new-password" component={NewPassword} />
      <Route exact path="/v2/reset-password" component={ResetPassword} />
      {/* End Authentication Routes */}
      <Route
        exact
        path="/v2/insurance-form/:patientHashId"
        component={InsuranceForm}
      />
      <Route
        path="/500"
        render={() => {
          window.location.href = '/500'
          return null
        }}
      />
      <Route
        path="*"
        render={() => {
          window.location.href = '/v2'
          return null
        }}
      />
    </Switch>
  )
}

export default MobileMainRoutes
