import React, { useState, useEffect } from 'react'
import {
  FullScreenCard,
  FormContainer,
  Row,
  ButtonContainer,
  CheckboxGroup,
  CheckboxLabel,
  StepIndicator,
  TitleContainer,
  ScrollableWrapper,
} from './styles'
import Grid from '@mui/material/Grid'
import Typography from '@/v2/components/common/Typography'
import { useTheme } from '@mui/material/styles'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import Button from '@/v2/components/common/Button'
import TextField from '@/v2/components/common/TextField'
import { verificationSchema, stepOneSchema, stepTwoSchema } from './rules'
import { formatPhone } from '@/v2/utils/format'
import {
  useAuthenticateInsurance,
  useCreateInsurance,
  useUpdateInsurance,
  useUpdateInsurancePrivate,
} from '@/v2/hooks/insurance/useInsuranceForm'
import SelectCalendar from '@/v2/components/common/SelectCalendar'

const InsuranceForm = () => {
  const theme = useTheme()
  const { patientHashId } = useParams()
  const { authenticateInsurance } = useAuthenticateInsurance(patientHashId)
  const { createInsurance } = useCreateInsurance(patientHashId)
  const { updateInsurance } = useUpdateInsurance(patientHashId)
  const [method, setMethod] = useState('post')
  const location = useLocation()
  const [step, setStep] = useState(1)
  const [formValues, setFormValues] = useState({})
  const [errors, setErrors] = useState({})
  const [isSigned, setIsSigned] = useState(false)
  const history = useHistory()
  const [patient, setPatient] = useState({})

  useEffect(() => {
    if (location?.state?.patient) {
      setIsSigned(true)
      const insuranceForm = location.state?.patient?.insuranceForm
      setPatient(location.state.patient)
      if (insuranceForm) {
        setStep(2)
        setMethod('put')
        setFormValues({
          ...insuranceForm.patient,
          ssn: insuranceForm.patient.socialSecurity,
          address: insuranceForm.patient.address.address1,
          aptUnit: insuranceForm.patient.address.address2,
          city: insuranceForm.patient.address.city,
          state: insuranceForm.patient.address.state,
          zipcode: insuranceForm.patient.address.zipcode,
          planNumber: insuranceForm.patient.planGroupNumber,
          policyHolderId: insuranceForm.patient.policyHolderSubscriberId,
          payerId: insuranceForm.patient.insurancePayerId,
          insuranceCoverageStartDate:
            insuranceForm.patient.insuranceCoverageStart,
          companyAddress1: insuranceForm.patient.companyAddress.address1,
          companyAddress2: insuranceForm.patient.companyAddress.address2,
          companyCity: insuranceForm.patient.companyAddress.city,
          companyState: insuranceForm.patient.companyAddress.state,
          companyZipcode: insuranceForm.patient.companyAddress.zipcode,
          policyHolderFirstName: insuranceForm?.subscriber?.firstName,
          policyHolderLastName: insuranceForm?.subscriber?.lastName,
          policyHolderGender: insuranceForm?.subscriber?.gender,
          policyHolderDateOfBirth: insuranceForm?.subscriber?.dateOfBirth,
          policyHolderEmployerName: insuranceForm?.subscriber?.employerName,
          policyHolderSsn: insuranceForm?.subscriber?.socialSecurity,
          relationToPatient: insuranceForm?.subscriber?.relationToPatient,
          policyHolderEmploymentLength:
            insuranceForm?.subscriber?.lengthOfEmployment,
          policyHolderPlanNumber: insuranceForm?.subscriber?.planGroupNumber,
          policyHolderPayerId: insuranceForm?.subscriber?.insurancePayerId,
          subscriberPolicyHolderId:
            insuranceForm?.subscriber?.policyHolderSubscriberId,
          policyHolderAddress: insuranceForm?.subscriber?.address?.address1,
          policyHolderAptUnit: insuranceForm?.subscriber?.address?.address2,
          policyHolderCity: insuranceForm?.subscriber?.address?.city,
          policyHolderState: insuranceForm?.subscriber?.address?.state,
          policyHolderZipcode: insuranceForm?.subscriber?.address?.zipcode,
        })
      }
    }
  }, [location])

  const { updateInsurancePrivate } = useUpdateInsurancePrivate(
    patient.id,
    patient?.insuranceForm?.id
  )

  const handleNext = async () => {
    if (step === 1) {
      try {
        await verificationSchema.validate(
          {
            verificationEmail: formValues.verificationEmail,
            verificationName: formValues.verificationName,
          },
          { abortEarly: false }
        )
        setErrors({})

        const res = await authenticateInsurance({
          patient_auth: {
            email: formValues.verificationEmail,
            full_name: formValues.verificationName,
          },
        })

        if (res.error) {
          setErrors({ verificationEmail: 'Invalid Email or Name' })
          return
        }

        if (!res.error) {
          setStep(prevStep => prevStep + 1)
          setFormValues({
            ...formValues,
            firstName: formValues.verificationName.split(' ')[0],
            lastName: formValues.verificationName.split(' ')[1],
            email: formValues.verificationEmail,
          })
          if (res.id) {
            setMethod('put')
            setFormValues({
              ...formValues,
              ...res.patient,
              ssn: res.patient.socialSecurity,
              address: res.patient.address.address1,
              aptUnit: res.patient.address.address2,
              city: res.patient.address.city,
              state: res.patient.address.state,
              zipcode: res.patient.address.zipcode,
              planNumber: res.patient.planGroupNumber,
              policyHolderId: res.patient.policyHolderSubscriberId,
              payerId: res.patient.insurancePayerId,
              insuranceCoverageStartDate: res.patient.insuranceCoverageStart,
              companyAddress1: res.patient.companyAddress.address1,
              companyAddress2: res.patient.companyAddress.address2,
              companyCity: res.patient.companyAddress.city,
              companyState: res.patient.companyAddress.state,
              companyZipcode: res.patient.companyAddress.zipcode,
              policyHolderFirstName: res?.subscriber?.firstName,
              policyHolderLastName: res?.subscriber?.lastName,
              policyHolderGender: res?.subscriber?.gender,
              policyHolderDateOfBirth: res?.subscriber?.dateOfBirth,
              policyHolderEmployerName: res?.subscriber?.employerName,
              policyHolderLengthOfEmployment:
                res?.subscriber?.lengthOfEmployment,
              policyHolderSsn: res?.subscriber?.socialSecurity,
              relationToPatient: res?.subscriber?.relationToPatient,
              policyHolderPlanNumber: res?.subscriber?.planGroupNumber,
              policyHolderPayerId: res?.subscriber?.insurancePayerId,
              subscriberPolicyHolderId:
                res?.subscriber?.policyHolderSubscriberId,
              policyHolderAddress: res?.subscriber?.address?.address1,
              policyHolderAptUnit: res?.subscriber?.address?.address2,
              policyHolderCity: res?.subscriber?.address?.city,
              policyHolderState: res?.subscriber?.address?.state,
              policyHolderZipcode: res?.subscriber?.address?.zipcode,
            })
          }
        }
      } catch (err) {
        const validationErrors = {}
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message
        })
        setErrors(validationErrors)
      }
    } else if (step === 2) {
      try {
        await stepOneSchema.validate(formValues, { abortEarly: false })
        setErrors({})
        setStep(prevStep => prevStep + 1)
      } catch (err) {
        const validationErrors = {}
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message
        })
        setErrors(validationErrors)
      }
    } else {
      setStep(prevStep => prevStep + 1)
    }
  }

  const handleBack = () => setStep(prevStep => prevStep - 1)

  const handleSubmit = async () => {
    try {
      setErrors({})

      const relevantFields = [
        'policyHolderFirstName',
        'policyHolderLastName',
        'policyHolderDateOfBirth',
        'policyHolderGender',
        'policyHolderAddress',
        'policyHolderAptUnit',
        'policyHolderZipcode',
        'policyHolderCity',
        'policyHolderState',
        'policyHolderSSN',
        'policyHolderPlanNumber',
        'policyHolderSubscriberId',
        'policyHolderEmployerName',
        'policyHolderLengthOfEmployment',
        'policyHolderPayerId',
        'relationToPatient',
      ]

      // Check if at least one of the fields is filled
      const hasValues = relevantFields.some(field => !!formValues[field])

      if (hasValues) {
        try {
          await stepTwoSchema.validate(formValues, { abortEarly: false })
        } catch (err) {
          const validationErrors = {}
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          setErrors(validationErrors)
          return // Stop submission if validation fails
        }
      }

      // Prepare payload
      const payload = {
        patient_auth: {
          email: formValues.verificationEmail,
          full_name: formValues.verificationName,
        },
        insurance_form: {
          insurance_form_patient_attributes: {
            first_name: formValues.firstName,
            last_name: formValues.lastName,
            gender: formValues.gender,
            date_of_birth: formValues.dateOfBirth,
            email: formValues.email,
            phone: formValues.phone,
            employer_name: formValues.employerName,
            social_security: formValues.ssn,
            insurance_company: formValues.insuranceCompany,
            insurance_coverage_start: formValues.insuranceCoverageStartDate,
            plan_group_number: formValues.planNumber,
            insurance_payer_id: formValues.payerId,
            policy_holder_subscriber_id: formValues.policyHolderId,
            address1: formValues.address,
            address2: formValues.aptUnit,
            city: formValues.city,
            state: formValues.state,
            zipcode: formValues.zipcode,
            company_address1: formValues.companyAddress1,
            company_address2: formValues.companyAddress2,
            company_city: formValues.companyCity,
            company_state: formValues.companyState,
            company_zipcode: formValues.companyZipcode,
          },
          insurance_form_subscriber_attributes: {
            first_name: formValues.policyHolderFirstName,
            last_name: formValues.policyHolderLastName,
            gender: formValues.policyHolderGender,
            date_of_birth: formValues.policyHolderDateOfBirth,
            employer_name: formValues.policyHolderEmployerName,
            social_security: formValues.policyHolderSSN,
            relation_to_patient: formValues.relationToPatient,
            length_of_employment: formValues.policyHolderLengthOfEmployment,
            plan_group_number: formValues.policyHolderPlanNumber,
            insurance_payer_id: formValues.policyHolderPayerId,
            policy_holder_subscriber_id: formValues.policyHolderSubscriberId,
            address1: formValues.policyHolderAddress,
            address2: formValues.policyHolderAptUnit,
            city: formValues.policyHolderCity,
            state: formValues.policyHolderState,
            zipcode: formValues.policyHolderZipcode,
          },
        },
      }

      let response
      if (isSigned) {
        response = await updateInsurancePrivate(payload)
      } else {
        response =
          method === 'post'
            ? await createInsurance(payload)
            : await updateInsurance(payload)
      }

      if (response.error) {
        setErrors({ form: 'Submission failed. Please check your details.' })
        return
      }

      setStep(prevStep => prevStep + 1)
    } catch (err) {
      console.error('Submission error:', err)
      setErrors({ form: 'Unexpected error occurred. Please try again.' })
    }
  }

  const renderVerificationStep = () => (
    <FormContainer>
      <Typography variant="body2" gutterBottom>
        Please verify your identity to continue
      </Typography>

      <Row>
        <TextField
          label="Full Name *"
          name="verificationName"
          placeholder="Full Name"
          fullWidth
          value={formValues.verificationName || ''}
          onChange={e => setFormValues({ ...formValues, verificationName: e })}
          error={errors?.verificationName}
        />
      </Row>
      <Row>
        <TextField
          label="Email *"
          name="verificationEmail"
          placeholder="example@example.com"
          fullWidth
          value={formValues.verificationEmail || ''}
          onChange={e => setFormValues({ ...formValues, verificationEmail: e })}
          error={errors?.verificationEmail}
        />
      </Row>
      <ButtonContainer>
        <Button onClick={handleNext} variant="contained" color="secondary">
          Next
        </Button>
      </ButtonContainer>
    </FormContainer>
  )

  const renderStepOne = () => (
    <FormContainer>
      <Row>
        <Grid item md={4}>
          <SelectCalendar
            label="Date of birth *"
            labelFormat="MM/dd/yyyy"
            isBasicCalendar
            value={formValues.dateOfBirth || ''}
            error={errors?.dateOfBirth}
            onChange={e => setFormValues({ ...formValues, dateOfBirth: e })}
          />
        </Grid>
        <TextField
          label="First Name *"
          name="firstName"
          placeholder="First Name"
          fullWidth
          value={formValues.firstName || ''}
          onChange={e => setFormValues({ ...formValues, firstName: e })}
          error={errors?.firstName}
        />
        <TextField
          label="Last Name *"
          name="lastName"
          placeholder="Last Name"
          fullWidth
          value={formValues.lastName || ''}
          onChange={e => setFormValues({ ...formValues, lastName: e })}
          error={errors?.lastName}
        />
      </Row>
      <Row>
        <Typography variant="body2" gutterBottom>
          Gender *
        </Typography>
      </Row>
      <Row>
        <CheckboxGroup>
          <CheckboxLabel>
            <input
              type="checkbox"
              checked={formValues.gender === 'male'}
              onChange={() => setFormValues({ ...formValues, gender: 'male' })}
            />
            Male
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              checked={formValues.gender === 'female'}
              onChange={() =>
                setFormValues({ ...formValues, gender: 'female' })
              }
            />
            Female
          </CheckboxLabel>
        </CheckboxGroup>
      </Row>
      <Row>
        {errors.gender && (
          <Typography color="error">{errors.gender}</Typography>
        )}
      </Row>
      <Row>
        <TextField
          label="Address *"
          name="address"
          placeholder="Start typing the address..."
          fullWidth
          value={formValues.address || ''}
          onChange={e => setFormValues({ ...formValues, address: e })}
          error={errors?.address}
        />
        <TextField
          label="Apt./Unit # *"
          name="aptUnit"
          placeholder="Apt./Unit #"
          fullWidth
          value={formValues.aptUnit || ''}
          onChange={e => setFormValues({ ...formValues, aptUnit: e })}
          error={errors?.aptUnit}
        />
      </Row>
      <Row>
        <TextField
          label="Zipcode *"
          name="zipcode"
          placeholder="Start typing the zipcode..."
          fullWidth
          value={formValues.zipcode || ''}
          onChange={e => setFormValues({ ...formValues, zipcode: e })}
          error={errors?.zipcode}
        />
        <TextField
          label="City *"
          name="city"
          placeholder="City"
          fullWidth
          value={formValues.city || ''}
          onChange={e => setFormValues({ ...formValues, city: e })}
          error={errors?.city}
        />
        <TextField
          label="State *"
          name="state"
          placeholder="State"
          fullWidth
          value={formValues.state || ''}
          onChange={e => setFormValues({ ...formValues, state: e })}
          error={errors?.state}
        />
      </Row>
      <Row>
        <TextField
          label="Social Security # *"
          name="ssn"
          placeholder="Social Security Number"
          fullWidth
          value={formValues.ssn || ''}
          onChange={e => setFormValues({ ...formValues, ssn: e })}
          error={errors?.ssn}
        />
        <TextField
          label="Phone *"
          name="phone"
          placeholder="Phone Number"
          fullWidth
          value={formValues.phone || ''}
          onChange={e =>
            setFormValues({ ...formValues, phone: formatPhone(e) })
          }
          error={errors?.phone}
        />
      </Row>
      <Row>
        <TextField
          label="Email *"
          name="email"
          placeholder="example@example.com"
          fullWidth
          value={formValues.email || ''}
          onChange={e => setFormValues({ ...formValues, email: e })}
          error={errors?.email}
        />
        <TextField
          label="Insurance Company *"
          name="insuranceCompany"
          placeholder="Insurance Company"
          fullWidth
          value={formValues.insuranceCompany || ''}
          onChange={e => setFormValues({ ...formValues, insuranceCompany: e })}
          error={errors?.insuranceCompany}
        />
      </Row>
      <Row>
        <Grid item md={4}>
          <SelectCalendar
            label="Coverage Started *"
            name="insuranceCoverageStartDate"
            labelFormat="MM/dd/yyyy"
            isBasicCalendar
            value={formValues.insuranceCoverageStartDate || ''}
            error={errors?.insuranceCoverageStartDate}
            onChange={e =>
              setFormValues({ ...formValues, insuranceCoverageStartDate: e })
            }
          />
        </Grid>
        <TextField
          label="Employer Name *"
          name="employerName"
          placeholder="Employer Name"
          fullWidth
          value={formValues.employerName || ''}
          onChange={e => setFormValues({ ...formValues, employerName: e })}
          error={errors?.employerName}
        />
      </Row>
      <Row>
        <TextField
          label="Insurance Company's Claim Address (listed on your card) *"
          name="companyAddress1"
          placeholder="Claim Address"
          fullWidth
          value={formValues.companyAddress1 || ''}
          onChange={e => setFormValues({ ...formValues, companyAddress1: e })}
          error={errors?.companyAddress1}
        />
        <TextField
          label="Insurance Company Apt./Unit # *"
          name="companyAddress2"
          placeholder="Apt./Unit #"
          fullWidth
          value={formValues.companyAddress2 || ''}
          onChange={e => setFormValues({ ...formValues, companyAddress2: e })}
          error={errors?.companyAddress2}
        />
      </Row>
      <Row>
        <TextField
          label="Insurance Company Zipcode *"
          name="companyZipcode"
          placeholder="Start typing the zipcode..."
          fullWidth
          value={formValues.companyZipcode || ''}
          onChange={e => setFormValues({ ...formValues, companyZipcode: e })}
          error={errors?.companyZipcode}
        />
        <TextField
          label="Insurance Company City *"
          name="companyCity"
          placeholder="City"
          fullWidth
          value={formValues.companyCity || ''}
          onChange={e => setFormValues({ ...formValues, companyCity: e })}
          error={errors?.companyCity}
        />
        <TextField
          label="Insurance Company State *"
          name="companyState"
          placeholder="State"
          fullWidth
          value={formValues.companyState || ''}
          onChange={e => setFormValues({ ...formValues, companyState: e })}
          error={errors?.companyState}
        />
      </Row>
      <Row>
        <TextField
          label="Plan/Group Number *"
          name="planNumber"
          placeholder="Plan/Group Number"
          fullWidth
          value={formValues.planNumber || ''}
          onChange={e => setFormValues({ ...formValues, planNumber: e })}
          error={errors?.planNumber}
        />
        <TextField
          label="Policy Holder/Subscriber ID# *"
          name="policyHolderId"
          placeholder="Policy Holder ID"
          fullWidth
          value={formValues.policyHolderId || ''}
          onChange={e => setFormValues({ ...formValues, policyHolderId: e })}
          error={errors?.policyHolderId}
        />
        <TextField
          label="Insurance Payer ID *"
          name="payerId"
          placeholder="Insurance Payer ID"
          fullWidth
          value={formValues.payerId || ''}
          onChange={e => setFormValues({ ...formValues, payerId: e })}
          error={errors?.payerId}
        />
      </Row>
      <ButtonContainer>
        <Button onClick={handleNext} variant="contained" color="secondary">
          Next
        </Button>
      </ButtonContainer>
    </FormContainer>
  )

  const renderStepTwo = () => (
    <FormContainer>
      <Typography variant="body2" gutterBottom>
        If you are a dependent, please complete the policy holder's information.
      </Typography>
      <Row>
        <Grid item md={4}>
          <SelectCalendar
            label="Date of birth"
            labelFormat="MM/dd/yyyy"
            isBasicCalendar
            value={formValues.policyHolderDateOfBirth || ''}
            error={errors?.policyHolderDateOfBirth}
            onChange={e =>
              setFormValues({ ...formValues, policyHolderDateOfBirth: e })
            }
          />
        </Grid>
        <TextField
          label="First Name"
          name="policyHolderFirstName"
          placeholder="First Name"
          fullWidth
          value={formValues.policyHolderFirstName || ''}
          onChange={e =>
            setFormValues({ ...formValues, policyHolderFirstName: e })
          }
          error={errors?.policyHolderFirstName}
        />
        <TextField
          label="Last Name"
          name="policyHolderLastName"
          placeholder="Last Name"
          fullWidth
          value={formValues.policyHolderLastName || ''}
          onChange={e =>
            setFormValues({ ...formValues, policyHolderLastName: e })
          }
          error={errors?.policyHolderLastName}
        />
      </Row>
      <Row>
        <CheckboxGroup>
          <CheckboxLabel>
            <input
              type="checkbox"
              checked={formValues.policyHolderGender === 'male'}
              onChange={() =>
                setFormValues({ ...formValues, policyHolderGender: 'male' })
              }
            />
            Male
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              checked={formValues.policyHolderGender === 'female'}
              onChange={() =>
                setFormValues({ ...formValues, policyHolderGender: 'female' })
              }
            />
            Female
          </CheckboxLabel>
        </CheckboxGroup>
      </Row>
      <Row>
        {errors.policyHolderGender && (
          <Typography color="error">{errors.policyHolderGender}</Typography>
        )}
      </Row>
      <Row>
        <TextField
          label="Address"
          name="policyHolderAddress"
          placeholder="Start typing the address..."
          fullWidth
          value={formValues.policyHolderAddress || ''}
          onChange={e =>
            setFormValues({ ...formValues, policyHolderAddress: e })
          }
          error={errors?.policyHolderAddress}
        />
        <TextField
          label="Apt./Unit #"
          name="policyHolderAptUnit"
          placeholder="Apt./Unit #"
          fullWidth
          value={formValues.policyHolderAptUnit || ''}
          onChange={e =>
            setFormValues({ ...formValues, policyHolderAptUnit: e })
          }
          error={errors?.policyHolderAptUnit}
        />
      </Row>
      <Row>
        <TextField
          label="Zipcode *"
          name="policyHolderZipcode"
          placeholder="Start typing the zipcode..."
          fullWidth
          value={formValues.policyHolderZipcode || ''}
          onChange={e =>
            setFormValues({ ...formValues, policyHolderZipcode: e })
          }
          error={errors?.policyHolderZipcode}
        />
        <TextField
          label="City *"
          name="policyHolderCity"
          placeholder="City"
          fullWidth
          value={formValues.policyHolderCity || ''}
          onChange={e => setFormValues({ ...formValues, policyHolderCity: e })}
          error={errors?.policyHolderCity}
        />
        <TextField
          label="State *"
          name="policyHolderState"
          placeholder="State"
          fullWidth
          value={formValues.policyHolderState || ''}
          onChange={e => setFormValues({ ...formValues, policyHolderState: e })}
          error={errors?.policyHolderState}
        />
      </Row>
      <Row>
        <TextField
          label="Social Security #"
          name="policyHolderSSN"
          placeholder="Social Security Number"
          fullWidth
          value={formValues.policyHolderSSN || ''}
          onChange={e => setFormValues({ ...formValues, policyHolderSSN: e })}
          error={errors?.policyHolderSSN}
        />
        <TextField
          label="Plan/Group Number"
          name="policyHolderPlanNumber"
          placeholder="Plan/Group Number"
          fullWidth
          value={formValues.policyHolderPlanNumber || ''}
          onChange={e =>
            setFormValues({ ...formValues, policyHolderPlanNumber: e })
          }
          error={errors?.policyHolderPlanNumber}
        />
      </Row>
      <Row>
        <TextField
          label="Policy Holder/Subscriber ID#"
          name="policyHolderSubscriberId"
          placeholder="Policy Holder ID"
          fullWidth
          value={formValues.policyHolderSubscriberId || ''}
          onChange={e =>
            setFormValues({ ...formValues, policyHolderSubscriberId: e })
          }
          error={errors?.policyHolderSubscriberId}
        />
        <TextField
          label="Employer Name"
          name="policyHolderEmployerName"
          placeholder="Employer Name"
          fullWidth
          value={formValues.policyHolderEmployerName || ''}
          onChange={e =>
            setFormValues({ ...formValues, policyHolderEmployerName: e })
          }
          error={errors?.policyHolderEmployerName}
        />
      </Row>
      <Row>
        <TextField
          label="Length of Employment"
          name="policyHolderLengthOfEmployment"
          placeholder="Years of Employment"
          fullWidth
          value={formValues.policyHolderLengthOfEmployment || ''}
          onChange={e =>
            setFormValues({ ...formValues, policyHolderLengthOfEmployment: e })
          }
          error={errors?.policyHolderLengthOfEmployment}
        />
        <TextField
          label="Insurance Payer ID (listed on insurance card)"
          name="policyHolderPayerId"
          placeholder="Insurance Payer ID"
          fullWidth
          value={formValues.policyHolderPayerId || ''}
          onChange={e =>
            setFormValues({ ...formValues, policyHolderPayerId: e })
          }
          error={errors?.policyHolderPayerId}
        />
      </Row>
      <Row>
        <Typography variant="body2" gutterBottom>
          Subscriber relationship to patient
        </Typography>
      </Row>
      <Row>
        <CheckboxGroup>
          <CheckboxLabel>
            <input
              type="checkbox"
              checked={formValues.relationToPatient === 'spouse'}
              onChange={() =>
                setFormValues({ ...formValues, relationToPatient: 'spouse' })
              }
            />
            Spouse
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              checked={formValues.relationToPatient === 'child'}
              onChange={() =>
                setFormValues({
                  ...formValues,
                  relationToPatient: 'child',
                })
              }
            />
            Dependent Child
          </CheckboxLabel>
          <CheckboxLabel>
            <input
              type="checkbox"
              checked={formValues.relationToPatient === 'other'}
              onChange={() =>
                setFormValues({ ...formValues, relationToPatient: 'other' })
              }
            />
            Other
          </CheckboxLabel>
        </CheckboxGroup>
      </Row>
      <Row>
        {errors.relationToPatient && (
          <Typography color="error">{errors.relationToPatient}</Typography>
        )}
      </Row>
      <ButtonContainer>
        <Button onClick={handleBack} variant="outlined" color="primary">
          Back
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="secondary">
          Submit
        </Button>
      </ButtonContainer>
    </FormContainer>
  )

  const renderSuccessStep = () => (
    <FormContainer>
      <Typography variant="h5" gutterBottom>
        🎉 Success!
      </Typography>
      <Typography variant="body1">
        Your insurance details have been submitted successfully.
      </Typography>
      {isSigned && (
        <Button
          onClick={() => history.goBack()}
          variant="contained"
          color="secondary"
        >
          Go back to Patient Profile
        </Button>
      )}
    </FormContainer>
  )

  const stepper = {
    1: renderVerificationStep(),
    2: renderStepOne(),
    3: renderStepTwo(),
    4: renderSuccessStep(),
  }

  const stepperTitle = {
    1: 'Verify Your Identity',
    2: "Enter the patient's information",
    3: "Enter the subscriber's information",
    4: 'Success!',
  }

  return (
    <ScrollableWrapper
      theme={theme}
    >
      <FullScreenCard isfull={`${step > 1 && step < 4}`}>
        <TitleContainer>
          <StepIndicator>{step}</StepIndicator>
          <Typography variant="h2" gutterBottom mt={1}>
            {stepperTitle[step]}
          </Typography>
        </TitleContainer>
        {stepper[step]}
      </FullScreenCard>
    </ScrollableWrapper>
  )
}

export default InsuranceForm
