import { useQuery, useQueryClient } from '@tanstack/react-query'
import { baseURL, requestMiddleware, headers } from '@/v2/constants/requests'

// Authenticate Insurance Form
export const useAuthenticateInsurance = patientHashId => {
  const { mutateAsync: authenticateInsurance, ...others } = requestMiddleware({
    key: ['AuthenticateInsurance', `${patientHashId}`],
    request: async authData =>
      await fetch(
        `/v2/rest/patients/${patientHashId}/insurance_form/authenticate`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify(authData),
        }
      ),
  })
  return { authenticateInsurance, ...others }
}

// Create Insurance Form
export const useCreateInsurance = patientHashId => {
  const queryClient = useQueryClient()
  const { mutateAsync: createInsurance, ...others } = requestMiddleware({
    key: ['CreateInsurance', `${patientHashId}`],
    request: async insurance =>
      await fetch(
        `${baseURL}/v2/rest/patients/${patientHashId}/insurance_form`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify(insurance),
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['Insurances', `${patientHashId}`],
      })
    },
  })

  return { createInsurance, ...others }
}

// Get Insurance Form
export const useGetInsurance = patientHashId => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['Insurances', `${patientHashId}`],
    queryFn: async () => {
      const res = await fetch(
        `${baseURL}/v2/rest/patients/${patientHashId}/insurances.json`
      )
      if (!res.ok) {
        throw new Error("We couldn't find the patient insurances")
      }
      const result = await res.json()
      return result
    },
    enabled: !!patientHashId,
  })

  return { insurances: data, isLoading, isError }
}

// Update Insurance Form
export const useUpdateInsurance = patientHashId => {
  const queryClient = useQueryClient()
  const { mutateAsync: updateInsurance, ...others } = requestMiddleware({
    key: ['UpdateInsurance', `${patientHashId}`],
    request: async insurance =>
      await fetch(
        `${baseURL}/v2/rest/patients/${patientHashId}/insurance_form`,
        {
          method: 'PUT',
          headers,
          body: JSON.stringify(insurance),
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['Insurances', `${patientHashId}`],
      })
    },
  })

  return { updateInsurance, ...others }
}

export const useSendInsuranceLink = patientId => {
  const { mutateAsync: sendInsuranceLink, ...others } = requestMiddleware({
    key: ['SendInsuranceLink', `${patientId}`],
    request: async () =>
      await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/profile/insurance_forms/send_email`,

        {
          method: 'POST',
          headers,
        }
      ),
  })

  return { sendInsuranceLink, ...others }
}

// Update Insurance Form
export const useUpdateInsurancePrivate = (patientId, insuranceId) => {
  const queryClient = useQueryClient()
  const { mutateAsync: updateInsurancePrivate, ...others } = requestMiddleware({
    key: ['UpdateInsurance', `${patientId}`],
    request: async insurance =>
      await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/profile/insurance_forms/${insuranceId}`,
        {
          method: 'PUT',
          headers,
          body: JSON.stringify(insurance),
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['Insurances', `${patientId}`],
      })
    },
  })

  return { updateInsurancePrivate, ...others }
}
