import React, { useState } from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Button from '@/v2/components/common/Button'
import Typography from '@/v2/components/common/Typography'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import { format, parseISO } from 'date-fns'
import { DAY_IN_US_FORMAT } from '@/v2/constants'
import { useHistory } from 'react-router-dom'
import { useSendInsuranceLink } from '@/v2/hooks/insurance/useInsuranceForm'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`
const Body = styled.div`
  display: flex;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

const PatientInsurance = () => {
  const theme = useTheme()
  const { patient } = usePatientContext()
  const insuranceForm = patient?.insuranceForm
  const history = useHistory()
  const { sendInsuranceLink } = useSendInsuranceLink(patient.id)
  const { onOpenSnackbar } = useSnackbarContext()
  const [disabled, setDisabled] = useState(false)

  const copyToClipboard = text => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(text)
        .then(() => {})
        .catch(err => {
          console.error('Failed to copy text: ', err)
        })
    } else {
      // Fallback method for older browsers
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      try {
        document.execCommand('copy')
      } catch (err) {
        console.error('Failed to copy text: ', err)
      }
      document.body.removeChild(textArea)
    }
  }

  const disableDebounce = (fn, time) => {
    setTimeout(() => {
      fn()
    }, time)
  }

  const handleInsuranceLink = async copyOnly => {
    if (!copyOnly) {
      const res = await sendInsuranceLink()
      if (!res.error) {
        setDisabled(true)

        disableDebounce(() => {
          setDisabled(false)
        }, 5000)

        onOpenSnackbar(res.message)
      }
      return
    }

    const link = `${window.location.origin}/v2/insurance-form/${patient.hashid}`
    copyToClipboard(link)
    onOpenSnackbar('URL copied to clipboard.')
  }

  return (
    <Paper sx={{ padding: '30px', marginBottom: '20px' }}>
      <Line>
        <Typography variant="h3" sx={{ mb: '28px' }}>
          Insurance Information
        </Typography>
        <Button
          variant="text"
          onClick={() =>
            !!insuranceForm
              ? history.push(`/v2/insurance-form/${patient.hashid}`, {
                  patient,
                })
              : handleInsuranceLink()
          }
          sx={{ color: theme.palette.gray.dark }}
          disabled={disabled}
        >
          <Typography variant="h5">
            {!!insuranceForm ? 'Edit' : 'Send Insurance Link'}
          </Typography>
        </Button>
      </Line>

      <Line mt="10px">
        <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
          {!!insuranceForm ? ' ' : 'No insurance information provided.'}
        </Typography>
        <Button
          variant="text"
          onClick={() => handleInsuranceLink(true)}
          sx={{ color: theme.palette.gray.dark }}
        >
          <Typography variant="h5">Copy Insurance Link</Typography>
        </Button>
      </Line>

      {!!insuranceForm && (
        <Body>
          <Column>
            <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
              Patient Name
            </Typography>
            <Typography
              bold
              fontSize="14px"
              sx={{ mb: '16px', mt: '2px', color: theme.palette.black.main }}
            >
              {insuranceForm?.patient?.firstName || 'Not informed'}{' '}
              {insuranceForm?.patient?.lastName}
            </Typography>
            <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
              Employer Name
            </Typography>
            <Typography
              bold
              fontSize="14px"
              sx={{ mb: '16px', mt: '2px', color: theme.palette.black.main }}
            >
              {insuranceForm?.patient?.employerName || 'Not informed'}
            </Typography>
            <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
              Insurance Company
            </Typography>
            <Typography
              bold
              fontSize="14px"
              sx={{ mb: '16px', mt: '2px', color: theme.palette.black.main }}
            >
              {insuranceForm?.patient?.insuranceCompany || 'Not informed'}
            </Typography>
            <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
              Insurance Coverage Start
            </Typography>
            <Typography
              bold
              fontSize="14px"
              sx={{ mb: '16px', mt: '2px', color: theme.palette.black.main }}
            >
              {insuranceForm?.patient?.insuranceCoverageStart
                ? format(
                    parseISO(insuranceForm?.patient?.insuranceCoverageStart),
                    DAY_IN_US_FORMAT
                  )
                : 'Not informed'}
            </Typography>
            <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
              Plan Group Number
            </Typography>
            <Typography
              bold
              fontSize="14px"
              sx={{ mb: '16px', mt: '2px', color: theme.palette.black.main }}
            >
              {insuranceForm?.patient?.planGroupNumber || 'Not informed'}
            </Typography>
            <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
              Insurance Payer ID
            </Typography>
            <Typography
              bold
              fontSize="14px"
              sx={{ mb: '16px', mt: '2px', color: theme.palette.black.main }}
            >
              {insuranceForm?.patient?.insurancePayerId || 'Not informed'}
            </Typography>
            <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
              Policy Holder Subscriber ID
            </Typography>
            <Typography
              bold
              fontSize="14px"
              sx={{ mb: '16px', mt: '2px', color: theme.palette.black.main }}
            >
              {insuranceForm?.patient?.policyHolderSubscriberId ||
                'Not informed'}
            </Typography>
            <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
              Company Address
            </Typography>
            <Typography
              bold
              fontSize="14px"
              sx={{ mb: '16px', mt: '2px', color: theme.palette.black.main }}
            >
              {insuranceForm?.patient?.companyAddress.address1 ||
                'Not informed'}
              ,{' '}
              {insuranceForm?.patient?.companyAddress.address2 ||
                'Not informed'}
              , {insuranceForm?.patient?.companyAddress.city || 'Not informed'},{' '}
              {insuranceForm?.patient?.companyAddress.state || 'Not informed'},{' '}
              {insuranceForm?.patient?.companyAddress.zipcode || 'Not informed'}
            </Typography>
          </Column>
          {insuranceForm?.subscriber?.firstName && (
            <Column>
              <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
                Subscriber Name
              </Typography>
              <Typography
                bold
                fontSize="14px"
                sx={{ mb: '16px', mt: '2px', color: theme.palette.black.main }}
              >
                {insuranceForm?.subscriber?.firstName || 'Not informed'}{' '}
                {insuranceForm?.subscriber?.lastName}
              </Typography>
              <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
                Subscriber Employer Name
              </Typography>
              <Typography
                bold
                fontSize="14px"
                sx={{ mb: '16px', mt: '2px', color: theme.palette.black.main }}
              >
                {insuranceForm?.subscriber?.employerName || 'Not informed'}
              </Typography>
              <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
                Subscriber SSN
              </Typography>
              <Typography
                bold
                fontSize="14px"
                sx={{ mb: '16px', mt: '2px', color: theme.palette.black.main }}
              >
                {insuranceForm?.subscriber?.socialSecurity || 'Not informed'}
              </Typography>
              <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
                Relationship to Patient
              </Typography>
              <Typography
                bold
                fontSize="14px"
                sx={{ mb: '16px', mt: '2px', color: theme.palette.black.main }}
              >
                {insuranceForm?.subscriber?.relationToPatient || 'Not informed'}
              </Typography>
              <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
                Length of Employment
              </Typography>
              <Typography
                bold
                fontSize="14px"
                sx={{ mb: '16px', mt: '2px', color: theme.palette.black.main }}
              >
                {insuranceForm?.subscriber?.lengthOfEmployment ||
                  'Not informed'}
              </Typography>
              <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
                Subscriber Plan Group Number
              </Typography>
              <Typography
                bold
                fontSize="14px"
                sx={{ mb: '16px', mt: '2px', color: theme.palette.black.main }}
              >
                {insuranceForm?.subscriber?.planGroupNumber || 'Not informed'}
              </Typography>
              <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
                Subscriber Insurance Payer ID
              </Typography>
              <Typography
                bold
                fontSize="14px"
                sx={{ mb: '16px', mt: '2px', color: theme.palette.black.main }}
              >
                {insuranceForm?.subscriber?.insurancePayerId || 'Not informed'}
              </Typography>
              <Typography variant="h6" sx={{ color: theme.palette.gray.dark }}>
                Subscriber Policy Holder Subscriber ID
              </Typography>
              <Typography
                bold
                fontSize="14px"
                sx={{ mb: '16px', mt: '2px', color: theme.palette.black.main }}
              >
                {insuranceForm?.subscriber?.policyHolderSubscriberId ||
                  'Not informed'}
              </Typography>
            </Column>
          )}
        </Body>
      )}
    </Paper>
  )
}

export default PatientInsurance
