import { useMutation, useQuery } from '@apollo/client'
import { format } from 'date-fns'
import AppointmentQuery from '@/v2/graphql/queries/Appointment.gql'
import UpdateAppointment from '@/v2/graphql/mutations/UpdateAppointment.gql'
import { nowOnClinicTimezone } from '../utils/convert'

const useAppointment = ({ appointmentId }) => {
  const { data, loading, error } = useQuery(AppointmentQuery, {
    variables: {
      id: appointmentId,
    },
    skip: !appointmentId,
  })
  const appointment = data?.appointment || null
  const [updateAppointment] = useMutation(UpdateAppointment)
  const currentDate = nowOnClinicTimezone()

  const trackEvent = (eventName, params) => {
    const clientId = appointment?.patient?.googleClientId || 'no_client_id'
    console.log('Tracking Event:', { ...params, clientId })
    // Trigger the analytics track event only if the patient has a googleClientId attached
    if (!appointment?.patient?.googleClientId) return
    window.analytics.track(eventName, { ...params, clientId })
  }

  const bookingCheckIn = async (value = true, id) => {
    try {
      await updateAppointment({
        variables: {
          appointment: {
            id: appointmentId || id,
            checkedIn: value,
            checkedInTime: value
              ? format(currentDate, 'yyyy-M-d hh:mm aa')
              : null,
          },
        },
      })
      // Analytics tracking
      trackEvent('Appointment Checked In', {
        category: 'Clearworks',
        label: 'Appointment checked in by staff',
        status: value ? 'checked_in' : 'unchecked',
        booking_id: appointmentId || id,
      })
    } catch (e) {
      throw Error(e)
    }
  }

  const seatPatient = async values => {
    try {
      await updateAppointment({
        variables: {
          appointment: {
            id: appointmentId || values.id,
            doctorId: values.doctorId,
            roomId: values.roomId,
            seated: values.seated,
            seatedTime: values.seated
              ? format(currentDate, 'yyyy-M-d hh:mm aa')
              : null,
          },
        },
        refetchQueries: ['NextAppointments'],
      })
      // Analytics tracking
      trackEvent('Patient Seated', {
        category: 'Clearworks',
        label: 'Patient seated by staff',
        status: values.seated ? 'seated' : 'not_seated',
        booking_id: appointmentId || values.id,
        seated_time: values.seated,
      })
    } catch (e) {
      throw Error(e)
    }
  }

  const confirmPatient = async value => {
    try {
      await updateAppointment({
        variables: {
          appointment: {
            id: appointmentId,
            completed: value,
          },
        },
      })
      // Analytics tracking
      trackEvent('Appointment Confirmed', {
        category: 'Clearworks',
        label: 'Appointment confirmed by staff',
        status: value ? 'completed' : 'not_completed',
        booking_id: appointmentId,
      })
    } catch (e) {
      throw Error(e)
    }
  }

  const toogleReschedule = async value => {
    try {
      await updateAppointment({
        variables: {
          appointment: {
            id: appointmentId,
            reschedule: value,
          },
        },
      })
      // Analytics tracking
      trackEvent('Appointment Rescheduled', {
        category: 'Clearworks',
        label: 'Appointment rescheduled by staff',
        status: value ? 'rescheduled' : 'not_rescheduled',
        booking_id: appointmentId,
      })
    } catch (e) {
      throw Error(e)
    }
  }

  const toogleNoShow = async value => {
    try {
      await updateAppointment({
        variables: {
          appointment: {
            id: appointmentId,
            noShow: value,
          },
        },
      })
      // Analytics tracking
      trackEvent('Patient no-show', {
        category: 'Clearworks',
        label: 'Patient no-show by staff',
        status: value ? 'no_show' : 'not_no_show',
        booking_id: appointmentId,
      })
    } catch (e) {
      throw Error(e)
    }
  }

  return {
    appointment,
    loading,
    error,
    bookingCheckIn,
    confirmPatient,
    seatPatient,
    toogleReschedule,
    toogleNoShow,
  }
}

export default useAppointment
