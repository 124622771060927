import * as Yup from 'yup'

export const verificationSchema = Yup.object().shape({
  verificationEmail: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  verificationName: Yup.string().required('Your Name is required'),
})

export const stepOneSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  dateOfBirth: Yup.string().required('Date of Birth is required'),
  gender: Yup.string()
    .oneOf(['male', 'female'], 'Gender is required')
    .required('Gender is required'),
  address: Yup.string().required('Address is required'),
  aptUnit: Yup.string(),
  zipcode: Yup.string().required('Zipcode is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string()
    .required('State is required')
    .length(2),
  ssn: Yup.string().required('Social Security # is required'),
  phone: Yup.string().required('Phone is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  insuranceCompany: Yup.string().required('Insurance Company is required'),
  companyAddress1: Yup.string().required('Claim Address is required'),
  companyAddress2: Yup.string(),
  companyCity: Yup.string().required('Claim City is required'),
  companyState: Yup.string()
    .required('Claim State is required')
    .length(2),
  companyZipcode: Yup.string().required('Claim Zipcode is required'),
  planNumber: Yup.string().required('Plan/Group Number is required'),
  policyHolderId: Yup.string().required('Policy Holder ID is required'),
  payerId: Yup.string().required('Insurance Payer ID is required'),
  employerName: Yup.string(),
  insuranceCoverageStartDate: Yup.string().required(
    'Insurance Coverage Start Date is required'
  ),
})

export const stepTwoSchema = Yup.object().shape({
  policyHolderFirstName: Yup.string().required('First Name is required'),
  policyHolderLastName: Yup.string().required('Last Name is required'),
  policyHolderDateOfBirth: Yup.date()
    .nullable()
    .required('Date of Birth is required'),
  policyHolderGender: Yup.string().required('Gender is required'),
  policyHolderAddress: Yup.string().required('Address is required'),
  policyHolderAptUnit: Yup.string().nullable(),
  policyHolderZipcode: Yup.string()
    .matches(/^\d{5}$/, 'Zipcode must be exactly 5 digits')
    .required('Zipcode is required'),
  policyHolderCity: Yup.string().required('City is required'),
  policyHolderState: Yup.string().required('State is required'),
  policyHolderSSN: Yup.string()
    .matches(/^\d{3}-\d{2}-\d{4}$/, 'Invalid SSN format')
    .nullable(),
  policyHolderPlanNumber: Yup.string().required(
    'Plan/Group Number is required'
  ),
  policyHolderSubscriberId: Yup.string().required(
    'Policy Holder/Subscriber ID is required'
  ),
  policyHolderEmployerName: Yup.string().required('Employer Name is required'),
  policyHolderLengthOfEmployment: Yup.string().required(
    'Length of Employment is required'
  ),
  policyHolderPayerId: Yup.string().required('Insurance Payer ID is required'),
  relationToPatient: Yup.string().required('Relation to Patient is required'),
})
