import styled from 'styled-components';

export const ScrollableWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Ensures content starts at the top */
  min-height: 100vh;
  width: 100%;
  padding: 1rem;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  overflow-y: auto; /* Enables full-page scrolling */
`;

export const FullScreenCard = styled.div`
  background-color: ${({ theme }) => theme?.palette?.common?.white || '#ffffff'};
  padding: 2rem;
  border-radius: 16px;
  text-align: center;
  width: 90%;
  max-width: 800px;
  max-height: 90vh; /* Ensures it doesn’t take up the entire screen */
  overflow-y: auto; /* Enables scrolling when content exceeds height */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 1rem; /* Slight spacing from the top */

  & > * {
    text-align: left;
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
    width: 95%;
    max-height: 85vh; /* Adjusts for smaller screens */
  }

  @media (max-width: 480px) {
    padding: 1rem;
    width: 100%;
    max-height: 80vh; /* Prevents excessive height on very small screens */
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  flex-wrap: wrap;

  & > * {
    flex: 1;
    min-width: 250px;
  }

  @media (max-width: 768px) {
    flex-direction: column; /* Ensure one input per line on mobile */
    gap: 0.5rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  gap: 1rem;
`;

export const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

export const StepIndicator = styled.div`
  display: inline-flex;
  background-color: ${({ theme }) => theme?.palette?.primary?.main || '#007BFF'};
  color: ${({ theme }) => theme?.palette?.common?.white || '#ffffff'};
  border-radius: 4px;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  margin-right: 0.5rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
`;
